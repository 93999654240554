import { isEurope, isChina } from './clientConfigService';

if (isEurope) {
	// Let GTM know the Trustarc stub script was already included by the server so it doesn't try to add it a second time.
	window.trustarcStubPreloaded = true;

	/**
	 * Stub script from trustarc. https://forbesmedia.atlassian.net/browse/PROD-2880
	 */
	/* eslint-disable func-names,no-var,vars-on-top,consistent-return,no-undef,prefer-rest-params,no-else-return */
	/* eslint-disable object-shorthand,comma-dangle,no-empty,no-underscore-dangle,prefer-arrow-callback */
	(function () {
		var makeStub = function () {
			var TCF_LOCATOR_NAME = '__tcfapiLocator';
			var TCF_LOCATOR_ID = '__tcfapiTrustarc';
			var win = window;
			var queue = [];
			var cmpFrame;
			function addFrame() {
				var doc = win.document;
				var otherCMP = !!(win.frames[TCF_LOCATOR_NAME]);
				if (!otherCMP) {
					if (doc.body) {
						var iframe = doc.createElement('iframe');
						iframe.name = TCF_LOCATOR_NAME;
						iframe.style.display = 'none';
						iframe.id = TCF_LOCATOR_ID;
						iframe.src = 'https://trustarc.mgr.consensu.org/asset/cmpcookie.v2.html';
						doc.body.appendChild(iframe);
					} else {
						setTimeout(addFrame, 5);
					}
				}
				return !otherCMP;
			}
			function tcfAPIHandler() {
				var args = arguments;
				var gdprApplies;
				if (!args.length) {
					/**
					 * shortcut to get the queue when the full CMP
					 * implementation loads; it can call tcfapiHandler()
					 * with no arguments to get the queued arguments
					 */
					return queue;
				} else if (args[0] === 'setGdprApplies') {
					/**
					 * shortcut to set gdprApplies if the publisher
					 * knows that they apply GDPR rules to all
					 * traffic (see the section on "What does the
					 * gdprApplies value mean" for more
					 */
					if (args.length > 3 && parseInt(args[1], 10) === 2 && typeof args[3] === 'boolean') {
						gdprApplies = args[3];
						if (typeof args[2] === 'function') {
							args[2]('set', true);
						}
					}
				} else if (args[0] === 'ping') {
					/**
					 * Only supported method; give PingReturn
					 * object as response
					 */
					var retr = {
						gdprApplies: gdprApplies,
						cmpLoaded: false,
						cmpStatus: 'stubCMP',
						apiVersion: '2.0'
					};
					if (typeof args[2] === 'function') {
						args[2](retr, true);
					}
				} else {
					/**
					 * some other method, just queue it for the
					 * full CMP implementation to deal with
					 */
					queue.push(args);
				}
			}
			function postMessageEventHandler(event) {
				var msgIsString = typeof event.data === 'string';
				var json = {};
				try {
					/**
					 * Try to parse the data from the event.  This is important
					 * to have in a try/catch because often messages may come
					 * through that are not JSON
					 */
					if (msgIsString) {
						json = JSON.parse(event.data);
					} else {
						json = event.data;
					}
				} catch (ignore) {}
				var payload = json.__tcfapiCall;
				if (payload) {
					window.__tcfapi(
						payload.command,
						payload.version,
						function (retValue, success) {
							var returnMsg = {
								__tcfapiReturn: {
									returnValue: retValue,
									success: success,
									callId: payload.callId,
								},
							};
							if (msgIsString) {
								returnMsg = JSON.stringify(returnMsg);
							}
							event.source.postMessage(returnMsg, '*');
						},
						payload.parameter
					);
				}
			}
			/**
			 * Iterate up to the top window checking for an already-created
			 * "__tcfapilLocator" frame on every level. If one exists already then we are
			 * not the master CMP and will not queue commands.
			 */
			while (win) {
				try {
					if (win.frames[TCF_LOCATOR_NAME]) {
						cmpFrame = win;
						break;
					}
				} catch (ignore) {}
				// if we're at the top and no cmpFrame
				if (win === window.top) {
					break;
				}
				// Move up
				win = win.parent;
			}
			if (!cmpFrame) {
				// we have recur'd up the windows and have found no __tcfapiLocator frame
				addFrame();
				win.__tcfapi = tcfAPIHandler;
				win.addEventListener('message', postMessageEventHandler, false);
			}
		};
		makeStub();
	}());
	/* eslint-enable func-names,no-var,vars-on-top,consistent-return,no-undef,prefer-rest-params,no-else-return */
	/* eslint-enable object-shorthand,comma-dangle,no-empty,no-underscore-dangle,prefer-arrow-callback */
}

/**
 * Load trustarc popup.
 */
function loadConsentScript() {
	// Add Cookie Preferences button.
	const borderTab = document.createElement('a');
	borderTab.id = 'borderTab';
	const teconsent = document.createElement('div');
	teconsent.id = 'teconsent';
	borderTab.appendChild(teconsent);
	document.body.appendChild(borderTab);

	// Add consent notice container
	const consentBlackbar = document.createElement('div');
	consentBlackbar.id = 'consent_blackbar';
	document.body.appendChild(consentBlackbar);

	// Add trustarc script
	const script = isEurope ? '//consent.trustarc.com/notice?domain=forbes_iab2.com&c=teconsent&js=nj&noticeType=bb&text=true&gtm=1' : '//consent.trustarc.com/notice?domain=forbes.com&js=nj&noticeType=bb&text=true&c=teconsent';
	const trustarcScript = document.createElement('script');
	trustarcScript.src = script;
	teconsent.appendChild(trustarcScript);
}

// Load trustarc here if we aren't loading GTM.
document.addEventListener('DOMContentLoaded', () => {
	const consentChoiceMade = document.cookie.indexOf('notice_gdpr_prefs=') !== -1;
	const haveFullConsent = document.cookie.indexOf('notice_gdpr_prefs=0,1,2') !== -1;

	if (isEurope || isChina) { // GDPR & PIPL
		if (!haveFullConsent) {
			loadConsentScript();
		}
	} else if (consentChoiceMade && !haveFullConsent) { // CCPA
		loadConsentScript();
	}
});

// Reload the page when we get a message from trustarc saying consent has changed.
function submitPreferencesHandler(message) {
	try {
		const result = JSON.parse(message.data);
		if (result && result.source === 'preference_manager' && result.message === 'submit_preferences') {
			window.location.reload();
		}
	} catch (e) {
		// Don't need to log every other message we receive.
	}
}

const apiObject = {
	PrivacyManagerAPI: {
		action: 'getConsent',
		timestamp: new Date().getTime(),
		self: window.location.host,
	},
};

const json = JSON.stringify(apiObject);
window.top.postMessage(json, '*');
window.addEventListener('message', submitPreferencesHandler, false);
